import {faBug, faXmark} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useState} from "react";
import {PageType} from "../../types/page-type";
import {VoteType} from "../../types/vote-type";
import {setPageType, setPresident, setTeamType, setVotingFinished} from "../reducers/root-reducer";
import {useAppDispatch, useAppSelector} from "../reducers/root-reducer-hooks";
import "./debug.scss";
import { TeamType } from "../../types/team";
import {backend} from "../services/backend/backend";

type DebugProps = {
    children: JSX.Element;
}

function Debug(props: DebugProps) {
    const [active, setActive] = useState(false);
    const dispatch = useAppDispatch();
    const state = useAppSelector((state) => state);
    const roundIndex = useAppSelector((state) => state.root.roundIndex);
    const dilemmaIndex = useAppSelector((state) => state.root.dilemmaIndex);
    const isPresident = useAppSelector((state) => state.root.isPresident);

    const openDebug = () => {
        setActive(true);
    };

    const closeDebug = () => {
        setActive(false)
    };

    const changePage = (type: string) => {
        Object.entries(PageType).forEach(([key, value]) => {
            if (key === type) {
                dispatch(setPageType(value as PageType));
            }
        });
    };

    function buildExecutePostDilemmaActionButton(roundIndex: number, dilemmaIndex: number) {
        return <button type="button" className="debug-content-button"
                       onClick={() => {
                           backend.executePostDilemmaAction(roundIndex, dilemmaIndex).then(value => {
                               console.log(`executePostDilemmaAction ${roundIndex} ${dilemmaIndex} returned`, value);
                           })
                       }}>executePostDilemmaAction {roundIndex} {dilemmaIndex}</button>;
    }

    return <React.Fragment>
        {active ? (
            <div className={"debug-container"}>
                <button className={"debug-close-button"} onClick={closeDebug}>
                    <FontAwesomeIcon icon={faXmark} className={"debug-close-button-icon"}/>
                </button>
                <div className={"debug-section"}>
                    <div className={"debug-header"}>
                        <span>Pages</span>
                    </div>
                    <div className="debug-content">
                        <div>
                            <label>Calls: </label>
                            <br/>
                            {buildExecutePostDilemmaActionButton(-1, -1)}
                            <br/>
                            {buildExecutePostDilemmaActionButton(0, -1)}
                            <br/>
                            {buildExecutePostDilemmaActionButton(0, 0)}
                            <br/>
                            {buildExecutePostDilemmaActionButton(1, -1)}
                            <br/>
                            {buildExecutePostDilemmaActionButton(1, 0)}
                            <br/>
                            {buildExecutePostDilemmaActionButton(1, 1)}
                            <br/>
                            <button type="button" className="debug-content-button"
                                    onClick={() => {
                                        backend.switchRound().then(value => {
                                            console.log(`switchRound returned`, value);
                                        })
                                    }}>switchRound</button>
                            <br/>
                        </div>
                    </div>
                    <div className={"debug-content"}>
                        <div>
                            <label>Change page: </label>
                            {Object.keys(PageType)
                                .filter(pageType => isNaN(Number(pageType)) && pageType !== PageType.Invalid)
                                .map((pageType) => {
                                    return <div key={pageType}>
                                        <button type={"button"} className={"debug-content-button"}
                                                onClick={() => changePage(pageType)}>{pageType}</button>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                </div>
                <div className={"debug-section"}>
                    <div className={"debug-header"}>
                        <span>President</span>
                    </div>
                    <div className={"debug-content"}>
                        <input id={"checkbox-president"} type={"checkbox"} checked={isPresident} onChange={(event) => {
                            dispatch(setPresident(event.target.checked));
                        }}/>
                        <label htmlFor={"checkbox-president"}>Is President</label>
                    </div>
                </div>
                <div className={"debug-section"}>
                    <div className={"debug-header"}>
                        <span>Team</span>
                    </div>
                    <div className={"debug-content"} style={{display: 'flex', gap: 12}}>
                        <button
                            type="button"
                            onClick={() => dispatch(setTeamType(TeamType.Blue))}
                            style={{fontWeight: state.root.teamType === TeamType.Blue ? 'bold' : undefined}}
                        >
                            Blue
                        </button>
                        <button
                            type="button"
                            onClick={() => dispatch(setTeamType(TeamType.Green))}
                            style={{fontWeight: state.root.teamType === TeamType.Green ? 'bold' : undefined}}
                        >
                            Green
                        </button>
                        <button
                            type="button"
                            onClick={() => dispatch(setTeamType(TeamType.Orange))}
                            style={{fontWeight: state.root.teamType === TeamType.Orange ? 'bold' : undefined}}
                        >
                            Orange
                        </button>
                        <button
                            type="button"
                            onClick={() => dispatch(setTeamType(TeamType.Purple))}
                            style={{fontWeight: state.root.teamType === TeamType.Purple ? 'bold' : undefined}}
                        >
                            Purple
                        </button>
                    </div>
                </div>
                <div className={"debug-section"}>
                    <div className={"debug-header"}>
                        <span>Voting</span>
                    </div>
                    <div className={"debug-content"}>
                        <button type={"button"} className={"debug-content-button"} onClick={() => {
                            dispatch(setVotingFinished(false));
                            backend.voteDilemma(roundIndex, dilemmaIndex, VoteType.Veto).then(() => {
                                dispatch(setVotingFinished(true));
                            });
                        }}>Veto
                        </button>
                        <button type={"button"} className={"debug-content-button"} onClick={() => {
                            dispatch(setVotingFinished(false));
                            backend.voteDilemma(roundIndex, dilemmaIndex, VoteType.Objection).then(() => {
                                dispatch(setVotingFinished(true));
                            });
                        }}>Objection
                        </button>
                        <button type={"button"} className={"debug-content-button"} onClick={() => {
                            dispatch(setVotingFinished(false));
                            backend.voteDilemma(roundIndex, dilemmaIndex, VoteType.Skip).then(() => {
                                dispatch(setVotingFinished(true));
                            });
                        }}>Skip
                        </button>

                    </div>
                </div>
                <div className={"debug-section"}>
                    <div className={"debug-header"}>
                        <span>State</span>
                    </div>
                    <div className={"debug-content"}>
                        <div className={"debug-state"}>
                            <pre>{JSON.stringify(state, null, 2)}</pre>
                        </div>
                    </div>
                </div>
            </div>
        ) : (
            <div className={"debug-button-container"}>
                <button type={"button"} className={"debug-button"} onClick={openDebug}>
                    <FontAwesomeIcon className={"debug-button-icon"} icon={faBug}/>
                </button>
            </div>
        )}
        {props.children}
    </React.Fragment>
}

export default Debug;