import {configureStore} from "@reduxjs/toolkit";
import {combineReducers} from "redux";
import rootReducer from "./reducers/root-reducer";

export const store = configureStore({
    reducer: combineReducers({
            root: rootReducer
        }
    )
});

export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;