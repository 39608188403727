import {io, Socket} from "socket.io-client";
import {AppNotificationType} from "../../../types/app-notification";
import {PresidentDiceResult} from "../../../types/dice-result";
import {Dilemma} from "../../../types/dilemma";
import {ClientToServerEvents, ServerToClientEvents} from "../../../types/events";
import {PageType} from "../../../types/page-type";
import {Round, RoundsData} from "../../../types/round";
import {Team, TeamAnswer, TeamResult, TeamTimer, TeamType, TeamVotes} from "../../../types/team";
import {VoteType} from "../../../types/vote-type";
import {
    addNotification, clearSocketId,
    resetState,
    setDilemmaIndex,
    setOpenRounds,
    setPageType,
    setPresident,
    setRollIndex, setRoundIndex,
    setScore,
    setScores,
    setSocketId,
    setVotingFinished
} from "../../reducers/root-reducer";
import {store} from "../../store";
import {BackendBase, reJoinTeamAndUpdateState} from "./backend";
import {JoinTeamResponse, SelectDilemmaOptionResponse, VoteDilemmaResponse} from "../../../types/responses";

class BackendSocket implements BackendBase {
    private _socket: Socket<ServerToClientEvents, ClientToServerEvents>;

    constructor() {
        const url: string = process.env.REACT_APP_BACKEND_URL || "";
        const useLocalSocketUrl = process.env.REACT_APP_USE_LOCAL === 'true';
        this._socket = useLocalSocketUrl ? io() : io(url);
        this.registerEvents();
    }

    private initialize(): void {
        store.dispatch(setSocketId(this._socket.id));

        this._socket.emit("getScores", (scores) => {
            store.dispatch(setScores(scores));
        });

        this._socket.emit("getOpenRounds", (openRounds) => {
            store.dispatch(setOpenRounds(openRounds));
        });
    }

    private registerEvents() {
        this._socket.on("connect", () => {
            this.initialize();
            console.log("Connected as: " + this._socket.id);

            // check for memberToken, try to reconnect in that case
            const memberToken = store.getState().root.memberToken;
            if (memberToken !== undefined) {
                console.log('found membertoken', memberToken);
                reJoinTeamAndUpdateState(memberToken.id).then(() => {
                    console.log('successfully reconnected');
                }).catch((reason) => {
                    console.error('error reconnecting', reason);
                    // reset the interface, but keep current socketId
                    const socketId = store.getState().root.socketId;
                    store.dispatch(resetState());
                    if (socketId !== undefined) {
                        store.dispatch(setSocketId(socketId));
                    }
                })
            }
        });

        this._socket.on("disconnect", () => {

            // Simon: don't reset on disconnect, this way we can continue working when reconnecting...
            // we might have te revisit this later, and do a full reset when disconnecting and restore on connect
            // but that would mess with all state that is not on the server.
            //
            // store.dispatch(resetState());

            store.dispatch(clearSocketId());

            console.log("Disconnected");
        });

        this._socket.io.on("reconnect_attempt", () => {
            console.log("Event: reconnect_attempt");
        });

        this._socket.io.on("reconnect", () => {
            console.log("Event: reconnect");
        });

        this._socket.on("playerConnect", () => {
            store.dispatch(addNotification({
                type: AppNotificationType.Team,
                message: "Een nieuwe speler is verbonden"
            }));
        });

        this._socket.on("playerDisconnect", () => {
            store.dispatch(addNotification({
                type: AppNotificationType.Error,
                message: "Een speler heeft de sessie verlaten"
            }));
        });

        this._socket.on("presidentDisconnect", (presidentId) => {
            store.dispatch(addNotification({
                type: AppNotificationType.Error,
                message: "De voorzitter is vertrokken"
            }));
            const myTokenId = store.getState().root.memberToken?.id;
            if (presidentId !== undefined && presidentId === myTokenId) {
                store.dispatch(setPresident(true));
                store.dispatch(addNotification({
                    type: AppNotificationType.Success,
                    message: "Jij bent de nieuwe voorzitter!"
                }));
            }
        });

        this._socket.on("setPage", (pageType) => {
            store.dispatch(setPageType(pageType));
        });

        this._socket.on("resetSession", () => {
            // reset the interface, but keep current socketId
            const socketId = store.getState().root.socketId;
            store.dispatch(resetState());
            if (socketId !== undefined) {
                store.dispatch(setSocketId(socketId));
            }
            this.initialize();
        });

        this._socket.on("updateOpenRounds", (rounds: Round[]) => {
            store.dispatch(setOpenRounds(rounds));
        });

        this._socket.on("votingFinished", () => {
            store.dispatch(setVotingFinished(true));
        });

        this._socket.on("setRollIndex", (rollIndex) => {
            store.dispatch(setRollIndex(rollIndex));
        });

        this._socket.on("executePostDilemmaAction", (presidentId, dilemmaIndex) => {
            const myTokenId = store.getState().root.memberToken?.id;
            store.dispatch(setPresident(presidentId === myTokenId));
            store.dispatch(setDilemmaIndex(dilemmaIndex));
            store.dispatch(setRollIndex(0));
            store.dispatch(setVotingFinished(false));
        });

        this._socket.on("updateScore", (teamType, score) => {
            const scoreDisplay = store.getState().root.scores[teamType];
            store.dispatch(setScore([teamType, {
                ...scoreDisplay,
                score: score
            }]));
        });

        this._socket.on("setBreakRoom", (teamType, inBreakRoom) => {
            const scoreDisplay = store.getState().root.scores[teamType];
            store.dispatch(setScore([teamType, {
                ...scoreDisplay,
                inBreakRoom: inBreakRoom
            }]));
        });

        this._socket.on("changePresident", (playerId) => {
            const myTokenId = store.getState().root.memberToken?.id;
            if (playerId !== undefined && playerId === myTokenId) {
                store.dispatch(setPresident(true));
                store.dispatch(addNotification({
                    type: AppNotificationType.Success,
                    message: "Jij bent de nieuwe voorzitter!"
                }));
            } else {
                const isPresident = store.getState().root.isPresident;
                if (isPresident) {
                    store.dispatch(setPresident(false));
                    store.dispatch(addNotification({
                        type: AppNotificationType.Error,
                        message: "Jij bent niet meer de voorzitter!"
                    }));
                }
            }
        });

        this._socket.on("nextRound", (data) => {
            store.dispatch(setPresident(false));
            store.dispatch(setDilemmaIndex(0));
            store.dispatch(setRollIndex(0));
            store.dispatch(setRoundIndex(data.roundIndex));
            store.dispatch(setPageType(PageType.PresidentDiceRoll));
        });

        this._socket.on("updateState", (state) => {
            const teamState = state.team;
            if (teamState !== undefined) {
                // not in use at the moment...
            }
        });
    }

    public setPage(pageType: PageType): void {
        this._socket?.emit("setPage", pageType);
    }

    public getName(): Promise<string | undefined> {
        return new Promise<string | undefined>((resolve, reject) => {
            try {
                this._socket.emit("getName", (name) => {
                    resolve(name);
                });
            } catch (error) {
                reject(error);
            }
        });
    }

    public getTeam(teamType: TeamType): Promise<Team> {
        return new Promise<Team>((resolve, reject) => {
            try {
                this._socket.emit("getTeam", teamType, (team) => {
                    resolve(team);
                });
            } catch (error) {
                reject(error);
            }
        });
    }

    public joinTeam(teamType: TeamType): Promise<JoinTeamResponse> {
        return new Promise<JoinTeamResponse>((resolve, reject) => {
            try {
                this._socket.emit("joinTeam", teamType, (team, memberToken) => {
                    resolve({team, token: memberToken});
                });
            } catch (error) {
                reject(error);
            }
        });
    }

    public reJoinTeam(tokenId: string): Promise<JoinTeamResponse|null> {
        return new Promise<JoinTeamResponse|null>((resolve, reject) => {
            try {
                this._socket.emit("reJoinTeam", tokenId, (joinTeamResponse) => {
                    resolve(joinTeamResponse);
                });
            } catch (error) {
                reject(error);
            }
        });
    }

    public getRoundsData(roundIndex: number): Promise<RoundsData> {
        return new Promise<RoundsData>((resolve, reject) => {
            try {
                this._socket.emit("getRoundsData", roundIndex, (roundsData) => {
                    resolve(roundsData);
                });
            } catch (error) {
                reject(error);
            }
        });
    }

    public switchRound(): Promise<number> {
        return new Promise<number>((resolve) => {
            this._socket.emit("switchRound", (value: number) => {
                resolve(value);
            });
        });
    }

    public getTimer(roundIndex: number): Promise<TeamTimer> {
        return new Promise<TeamTimer>((resolve) => {
            this._socket.emit("getTimer", roundIndex, (timer) => {
                resolve(timer);
            });
        });
    }

    public getPresidentDiceRolls(): Promise<PresidentDiceResult[]> {
        return new Promise<PresidentDiceResult[]>((resolve, reject) => {
            try {
                this._socket?.emit("getPresidentDiceRolls", (rolls) => {
                    resolve(rolls);
                });
            } catch (error) {
                reject(error);
            }
        });
    }

    public getDilemma(roundIndex: number, dilemmaIndex: number): Promise<Dilemma> {
        return new Promise<Dilemma>((resolve, reject) => {
            try {
                this._socket?.emit("getDilemma", roundIndex, dilemmaIndex, (data) => {
                    if (data !== null) {
                        resolve(data);
                    }
                });
            } catch (error) {
                reject(error);
            }
        });
    }

    public getLastAnswer(roundIndex: number, dilemmaIndex: number): Promise<TeamAnswer | null> {
        return new Promise<TeamAnswer | null>((resolve, reject) => {
            try {
                this._socket?.emit("getLastAnswer", roundIndex, dilemmaIndex, (answer) => {
                    resolve(answer);
                });
            } catch (error) {
                reject(error);
            }
        });
    }

    public selectDilemmaOption(roundIndex: number, dilemmaIndex: number, optionIndex: number): Promise<SelectDilemmaOptionResponse | null> {
        return new Promise<SelectDilemmaOptionResponse | null>((resolve, reject) => {
            try {
                this._socket?.emit("selectDilemmaOption", roundIndex, dilemmaIndex, optionIndex, (resonse) => {
                    resolve(resonse);
                });
            } catch (error) {
                reject(error);
            }
        });
    }

    public voteDilemma(roundIndex: number, dilemmaIndex: number, voteType: VoteType): Promise<VoteDilemmaResponse | null> {
        return new Promise<VoteDilemmaResponse | null>((resolve, reject) => {
            try {
                this._socket?.emit("voteDilemma", roundIndex, dilemmaIndex, voteType, (response) => {
                    resolve(response);
                });
            } catch (error) {
                reject(error);
            }
        });
    }

    public getVotes(): Promise<TeamVotes | null> {
        return new Promise<TeamVotes | null>((resolve, reject) => {
            try {
                this._socket?.emit("getVotes", (votes) => {
                    resolve(votes);
                });
            } catch (error) {
                reject(error);
            }
        });
    }

    public getVoteFinished(roundIndex: number, dilemmaIndex: number): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            try {
                this._socket?.emit("getVoteFinished", roundIndex, dilemmaIndex, (isVoteFinished) => {
                    resolve(isVoteFinished);
                });
            } catch (error) {
                reject(error);
            }
        });
    }

    public generateDilemmaResults(roundIndex: number, dilemmaIndex: number): Promise<TeamResult[]> {
        return new Promise<TeamResult[]>((resolve, reject) => {
            try {
                this._socket?.emit("generateDilemmaResults", roundIndex, dilemmaIndex, (results) => {
                    resolve(results);
                });
            } catch (error) {
                reject(error);
            }
        });
    }

    public setRollIndex(rollIndex: number): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            try {
                this._socket?.emit("setRollIndex", rollIndex, () => {
                    resolve();
                });
            } catch (error) {
                reject(error);
            }
        });
    }

    public executePostDilemmaAction(roundIndex: number, dilemmaIndex: number): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            try {
                this._socket?.emit("executePostDilemmaAction", roundIndex, dilemmaIndex, () => {
                    resolve();
                });
            } catch (error) {
                reject(error);
            }
        });
    }

    public processScore(roundIndex: number, dilemmaIndex: number, rollIndex: number): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            try {
                this._socket.emit("processScore", roundIndex, dilemmaIndex, rollIndex, () => {
                    resolve();
                });
            } catch (error) {
                reject(error);
            }
        });
    }

    public onEnterBreakRoom(teamType: TeamType): void {
        this._socket.emit("setBreakRoom", teamType, true);
    }

    public onLeaveBreakRoom(teamType: TeamType): void {
        this._socket.emit("setBreakRoom", teamType, false);
    }
}

export default BackendSocket;