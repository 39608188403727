import Dilemmas from "../../../content/dilemmas.json";
import RoundsConfig from "../../../content/rounds";
import {PresidentDiceResult} from "../../../types/dice-result";
import {Dilemma} from "../../../types/dilemma";
import {PageType} from "../../../types/page-type";
import {Round, RoundsData} from "../../../types/round";
import {Team, TeamAnswer, TeamResult, TeamResultType, TeamTimer, TeamType, TeamVotes} from "../../../types/team";
import {VoteType} from "../../../types/vote-type";
import {setDilemmaIndex, setOpenRounds, setPageType, setRollIndex, setScore} from "../../reducers/root-reducer";
import {store} from "../../store";
import {BackendBase} from "./backend";
import {JoinTeamResponse, SelectDilemmaOptionResponse, VoteDilemmaResponse} from "../../../types/responses";

const createRoundEndTime = (seconds: number) => {
    return Date.now() + (seconds * 1000);
}

const rounds: Round[] = [
    {
        roundNumber: undefined,
        isOpen: true
    },
    {
        roundNumber: 0,
        isOpen: true
    },
    {
        roundNumber: 1,
        isOpen: true
    },
    {
        roundNumber: 2,
        isOpen: true
    }
];

const teams: Team[] = Object.values(TeamType).map((teamType) => {
    return {
        type: teamType as TeamType,
        presidentId: undefined,
        needPresident: false,
        startTimestamp: undefined,
        score: {
            budget: 100,
            reputation: 100
        },
        votes: {
            vetos: 3,
            objections: 3
        },
        progress: {
            pageType: PageType.ScreenSelection,
            roundIndex: 0,
            dilemmaIndex: 0,
            rollIndex: 0
        },
        answers: [],
        timers: [],
        nameCounter: 1,
        members: [],
        rounds: [],
    }
});

const lastAnswer: TeamAnswer = {
    roundIndex: 0,
    dilemmaIndex: 0,
    optionIndex: 0,
    stars: {
        budget: 0,
        reputation: 0
    },
    votes: {
        vetos: 0,
        objections: 0
    },
    voters: [],
    results: [
        {
            type: TeamResultType.Budget,
            eyes: 6,
            actionTitle: "Budget Test",
            resultValue: 10,
            isProcessed: false
        },
        {
            type: TeamResultType.Reputation,
            eyes: 6,
            actionTitle: "Reputation Test",
            resultValue: 10,
            isProcessed: false
        }
    ]
};

class BackendLocal implements BackendBase {
    constructor() {
        store.dispatch(setOpenRounds(rounds));
    }

    public setPage(pageType: PageType): void {
        store.dispatch(setPageType(pageType));
    }

    public getName(): Promise<string | undefined> {
        return new Promise<string | undefined>((resolve) => {
            resolve("Local-0");
        });
    }

    public getTeam(teamType: TeamType): Promise<Team> {
        return new Promise<Team>((resolve) => {
            const team = teams.find((team) => team.type === teamType) as Team;
            resolve(team);
        });
    }

    public joinTeam(teamType: TeamType): Promise<JoinTeamResponse> {
        return new Promise<JoinTeamResponse>((resolve) => {
            const team = teams.find((team) => {
                return team.type === teamType;
            });

            if (team) {
                resolve({team, token: {id: 'fake', name: 'TODO IMPLEMENT'}});
            }
        });
    }

    public reJoinTeam(tokenId: string): Promise<JoinTeamResponse> {
        return new Promise<JoinTeamResponse>((resolve) => {
            const team = teams.find((team) => {
                return team.type === TeamType.Green;
            });

            if (team) {
                resolve({team, token: {id: 'fake', name: 'TODO IMPLEMENT'}});
            }
        });
    }

    public getRoundsData(roundIndex: number): Promise<RoundsData> {
        return new Promise<RoundsData>((resolve) => {
            resolve({
                currentRound: 0,
                totalRounds: 0
            });
        });
    }

    public switchRound(): Promise<number> {
        const nextRoundIdx = -99;
        return new Promise<number>((resolve) => {
            resolve(nextRoundIdx);
        });
    }

    public getTimer(roundIndex: number): Promise<TeamTimer> {
        return new Promise<TeamTimer>((resolve) => {
            resolve({
                roundIndex: roundIndex,
                targetTime: createRoundEndTime(RoundsConfig[roundIndex].seconds)
            });
        });
    }

    public getPresidentDiceRolls(): Promise<PresidentDiceResult[]> {
        return new Promise<PresidentDiceResult[]>((resolve) => {
            resolve([{
                eyes: 6,
                isPresident: false
            }, {
                eyes: 6,
                isPresident: true
            }]);
        });
    }

    public getDilemma(roundIndex: number, dilemmaIndex: number): Promise<Dilemma> {
        return new Promise<Dilemma>((resolve) => {
            const dilemma = Dilemmas[0].dilemmas[0];
            resolve(dilemma);
        });
    }

    public getLastAnswer(roundIndex: number, dilemmaIndex: number): Promise<TeamAnswer | null> {
        return new Promise<TeamAnswer | null>((resolve) => {
            resolve(lastAnswer);
        });
    }

    public selectDilemmaOption(roundIndex: number, dilemmaIndex: number, optionIndex: number): Promise<SelectDilemmaOptionResponse | null> {
        return new Promise<SelectDilemmaOptionResponse | null>((resolve) => {
            const teamType = store.getState().root.teamType;
            this.getTeam(teamType).then((team => {
                team.answers.push({
                    roundIndex: roundIndex,
                    dilemmaIndex: dilemmaIndex,
                    optionIndex: optionIndex,
                    stars: {
                        budget: 0,
                        reputation: 0
                    },
                    votes: {
                        vetos: 0,
                        objections: 0
                    },
                    voters: [],
                    results: []
                });

                resolve({success: true});
            }));
        });
    }

    public getVotes(): Promise<TeamVotes | null> {
        return new Promise<TeamVotes | null>((resolve) => {
            const teamType = store.getState().root.teamType;
            this.getTeam(teamType).then((team) => {
                resolve(team.votes);
            });
        });
    }

    public getVoteFinished(roundIndex: number, dilemmaIndex: number): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            resolve(false);
        });
    }

    public voteDilemma(roundIndex: number, dilemmaIndex: number, voteType: VoteType): Promise<VoteDilemmaResponse | null> {
        return new Promise<VoteDilemmaResponse | null>((resolve) => {
            lastAnswer.votes.vetos = 0;
            lastAnswer.votes.objections = 0;

            if (voteType === "veto") {
                lastAnswer.votes.vetos += 1;
            } else if (voteType === "objection") {
                lastAnswer.votes.objections += 1;
            }

            resolve({success: true});
        });
    }

    public generateDilemmaResults(roundIndex: number, dilemmaIndex: number): Promise<TeamResult[]> {
        return new Promise<TeamResult[]>((resolve) => {
            resolve([
                {
                    type: TeamResultType.Budget,
                    eyes: 6,
                    actionTitle: "Budget Test",
                    resultValue: 10,
                    isProcessed: false
                },
                {
                    type: TeamResultType.Reputation,
                    eyes: 6,
                    actionTitle: "Reputation Test",
                    resultValue: 10,
                    isProcessed: false
                }
            ]);
        });
    }

    public setRollIndex(rollIndex: number): Promise<void> {
        return new Promise<void>((resolve) => {
            store.dispatch(setRollIndex(rollIndex));
            resolve();
        });
    }

    public executePostDilemmaAction(roundIndex: number, dilemmaIndex: number): Promise<void> {
        return new Promise<void>((resolve) => {
            const round = rounds[roundIndex];
            const dilemmasInRound = Dilemmas[round.roundNumber as number].dilemmas.length;

            // let nextPage: PageType = PageType.Dilemma;
            let nextDilemmaIndex = dilemmaIndex + 1;

            if (nextDilemmaIndex > dilemmasInRound - 1) {
                nextDilemmaIndex = 0;
                // nextPage = PageType.RoundWaitingRoom;
            }

            store.dispatch(setDilemmaIndex(nextDilemmaIndex));
            // send setPage(nextPage)
            resolve();
        });
    }

    public processScore(roundIndex: number, dilemmaIndex: number, rollIndex: number): Promise<void> {
        return new Promise<void>((resolve) => {
            resolve();
        });
    }

    public onEnterBreakRoom(teamType: TeamType): void {
        const score = store.getState().root.scores[teamType];
        store.dispatch(setScore([teamType, {
            ...score,
            inBreakRoom: true
        }]));
    }

    public onLeaveBreakRoom(teamType: TeamType): void {
        const score = store.getState().root.scores[teamType];
        store.dispatch(setScore([teamType, {
            ...score,
            inBreakRoom: false
        }]));
    }
}

export default BackendLocal;