import React from 'react';
import ReactDOM from 'react-dom/client';
import {Provider} from "react-redux";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Debug from "./app/debug/debug";
import {store} from "./app/store";
import Loading from "./loading";
import {MEMBER_TOKEN_STORAGE_KEY, SCREEN_SELECTION_STORAGE_KEY} from "./shared/constants";
import {setPageType, setScreenSelection, setTeamType} from "./app/reducers/root-reducer";
import {joinTeamAndUpdateState, reJoinTeamAndUpdateState} from "./app/services/backend/backend";
import {MemberToken, TeamType} from "./types/team";
import {PageType, ScreenSelectionElement} from "./types/page-type";

const App = React.lazy(() => import("./app/app"));
const Management = React.lazy(() => import("./management/management"));

const debug: boolean = process.env.REACT_APP_DEBUG_MODE ? JSON.parse(process.env.REACT_APP_DEBUG_MODE) : false;

/**
 * Checks team param and loads state accordingly, only to be used if there is no (valid) team member token.
 */
function checkForTeamParamAndIntialize() {
    let params = new URLSearchParams(document.location.search);
    let teamParam = params.get("team");
    console.log('checkForTeamParamAndIntialize', teamParam);
    if (teamParam !== null) {
        if (Object.values(TeamType).includes(teamParam as TeamType)) {
            window.sessionStorage.setItem(SCREEN_SELECTION_STORAGE_KEY, ScreenSelectionElement.Finished);
            store.dispatch(setPageType(PageType.ScreenSelection));
            store.dispatch(setTeamType(teamParam as TeamType));
            joinTeamAndUpdateState(teamParam as TeamType).then(() => {
                store.dispatch(setScreenSelection(ScreenSelectionElement.Finished));
            }).catch(reason => {
                console.error('error joining team', reason);
            });
        } else {
            console.log('invalid team parameter:', teamParam);
        }
    }
}

// update store if member token is available
const serializedMemberToken = window.sessionStorage.getItem(MEMBER_TOKEN_STORAGE_KEY);
const screenSelectionState = window.sessionStorage.getItem(SCREEN_SELECTION_STORAGE_KEY);
if (serializedMemberToken !== null) {
    const memberToken: MemberToken = JSON.parse(serializedMemberToken);
    const tokenId = memberToken.id;
    // re-join team?
    reJoinTeamAndUpdateState(tokenId).then(() => {
        // update screen selection state if available
        if (screenSelectionState !== null && Object.values(ScreenSelectionElement).includes(screenSelectionState as ScreenSelectionElement)) {
            store.dispatch(setScreenSelection(screenSelectionState as ScreenSelectionElement));
            if (screenSelectionState !== ScreenSelectionElement.Finished) {
                store.dispatch(setPageType(PageType.ScreenSelection));
            }
        }
    }).catch(e => {
        console.log('reJoinTeam failed', e);

        // Only check search param 'team' if there is no existing or valid user
        checkForTeamParamAndIntialize();
    });
} else {
    // Only check search param 'team' if there is no existing or valid user
    checkForTeamParamAndIntialize();
}

const app: JSX.Element =
    <Provider store={store}>
        {debug ? (
            <Debug>
                <App/>
            </Debug>
        ) : (
            <App/>
        )}
    </Provider>

const management: JSX.Element =
    <Management/>;

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <React.Suspense fallback={<Loading/>}>
        <BrowserRouter>
            <Routes>
                <Route path={"/"} element={app}/>
                <Route path={"/manage"} element={management}/>
            </Routes>
        </BrowserRouter>
    </React.Suspense>
);