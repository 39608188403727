export enum PageType {
    Invalid = "invalid",
    SessionWaitingRoom = "session-waiting-room",
    TeamSelection = "team-selection",
    ScreenSelection = "screen-selection",
    PresidentDiceRoll = "president-dice-roll",
    PresidentResult = "president-result",
    Dilemma = "dilemma",
    DilemmaVotes = "dilemma-votes",
    DilemmaDiceRoll = "dilemma-dice-roll",
    RoundWaitingRoom = "round-waiting-room",
    RoundTimeoutRoom = "round-timeout-room",
    End = "end"
}

export enum ScreenSelectionElement {
    Entry = "screen-selection-entry",
    Phone = "screen-selection-phone",
    Finished = "screen-selection-finished"
}