import {PageType} from "./page-type";
import {PresidentDiceResults} from "./dice-result";
import {VoteType} from "./vote-type";

export enum TeamType {
    None = "none",
    Green = "green",
    Purple = "purple",
    Orange = "orange",
    Blue = "blue"
}

export enum TeamResultType {
    Budget = "budget",
    Reputation = "reputation"
}

export type Team = {
    type: TeamType;
    presidentId: string | undefined;
    needPresident: boolean;
    score: TeamScore;
    votes: TeamVotes;
    progress: TeamProgress;
    answers: TeamAnswer[];
    timers: TeamTimer[];
    nameCounter: number;
    members: MemberToken[];
    rounds: TeamRoundData[];
}

export interface MemberToken {
    id: string;
    name: string;
    socketId?: string;
}

export type TeamScore = {
    budget: number;
    reputation: number;
}

export type TeamScoreDisplay = {
    inBreakRoom: boolean;
    score: TeamScore;
}

// defined here to be accessible by both app and server code
export const INITIAL_VETO_VOTES = 3;
export const INITIAL_OBJECTION_VOTES = 3;

export type TeamVotes = {
    vetos: number;
    objections: number;
}

export type TeamProgress = {
    pageType: PageType,
    roundIndex: number;
    dilemmaIndex: number;
    rollIndex: number;
}

export interface MemberVote {
    tokenId: string;
    vote: VoteType;
}

export type TeamAnswer = {
    roundIndex: number;
    dilemmaIndex: number;
    optionIndex: number;
    stars: TeamScore;
    votes: TeamVotes;
    voters: MemberVote[];
    results: TeamResult[];
}

export type TeamResult = {
    type: TeamResultType;
    eyes: number | undefined;
    actionTitle: string;
    resultValue: any;
    isProcessed: boolean;
}

export type TeamTimer = {
    roundIndex: number;
    targetTime: number | undefined;
}

export interface TeamRoundData {
    presidentDiceRoll?: PresidentDiceResults;
}