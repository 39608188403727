import {createAction, createReducer} from "@reduxjs/toolkit";
import {AppNotification} from "../../types/app-notification";
import {PageType, ScreenSelectionElement} from "../../types/page-type";
import {Round} from "../../types/round";
import {MemberToken, TeamScoreDisplay, TeamType} from "../../types/team";
import {generateRandomId} from "../hooks/generateRandomId";
import {DilemmaVote} from "../../types/dilemma";

export interface RootState {
    socketId: string | undefined;
    name: string | undefined;
    isPresident: boolean;
    pageType: PageType;
    teamType: TeamType;
    memberToken?: MemberToken;
    openRounds: Round[];
    roundIndex: number;
    dilemmaIndex: number;
    rollIndex: number;
    isVotingFinished: boolean;
    scores: { [key in TeamType]: TeamScoreDisplay };
    notifications: Array<AppNotification & { addedAt: number; id: string }>;
    isLoading: boolean;
    screenSelection: ScreenSelectionElement;
    dilemmaVotes: DilemmaVote[];
}

const initialState: RootState = {
    socketId: undefined,
    name: undefined,
    isPresident: false,
    pageType: PageType.SessionWaitingRoom,
    teamType: TeamType.None,
    openRounds: [],
    roundIndex: -1,
    dilemmaIndex: -1,
    rollIndex: -1,
    isVotingFinished: false,
    scores: {
        [TeamType.None]: {
            inBreakRoom: false,
            score: {
                budget: 0,
                reputation: 0,
            },
        },
        [TeamType.Green]: {
            inBreakRoom: false,
            score: {
                budget: 0,
                reputation: 0,
            },
        },
        [TeamType.Purple]: {
            inBreakRoom: false,
            score: {
                budget: 0,
                reputation: 0,
            },
        },
        [TeamType.Orange]: {
            inBreakRoom: false,
            score: {
                budget: 0,
                reputation: 0,
            },
        },
        [TeamType.Blue]: {
            inBreakRoom: false,
            score: {
                budget: 0,
                reputation: 0,
            },
        },
    },
    notifications: [],
    isLoading: false,
    screenSelection: ScreenSelectionElement.Entry,
    dilemmaVotes: [],
};

export const resetState = createAction<void>("root/resetState");
export const setSocketId = createAction<string>("root/setSocketId");
export const clearSocketId = createAction<void>("root/clearSocketId");
export const setPresident = createAction<boolean>("root/setPresident");
export const setPageType = createAction<PageType>("root/setPage");
export const setName = createAction<string | undefined>("root/setName");
export const setTeamType = createAction<TeamType>("root/setTeamType");
export const setMemberToken = createAction<MemberToken>("root/setMemberToken");
export const clearMemberToken = createAction<void>("root/clearMemberToken");
export const setOpenRounds = createAction<Round[]>("root/setOpenRounds");
export const setRoundIndex = createAction<number>("root/setRoundIndex");
export const setDilemmaIndex = createAction<number>("root/setDilemmaIndex");
export const setRollIndex = createAction<number>("root/setRollIndex");
export const setVotingFinished = createAction<boolean>("root/setVotingFinished");
export const setScores = createAction<{ [key in TeamType]: TeamScoreDisplay }>("root/setScores");
export const setScore = createAction<[TeamType, TeamScoreDisplay]>("root/setScore");
export const setNotifications = createAction<AppNotification[]>("root/setNotifications");
export const addNotification = createAction<AppNotification>("root/addNotification");
export const setLoading = createAction<boolean>("root/setLoading");
export const setScreenSelection = createAction<ScreenSelectionElement>("root/setScreenSelection");
export const addDilemmaVote = createAction<DilemmaVote>("root/addDilemmaVote");

const rootReducer = createReducer(initialState, (builder) => {
    builder.addCase(resetState, () => {
        return initialState;
    });

    builder.addCase(setSocketId, (state, action) => {
        state.socketId = action.payload;
    });

    builder.addCase(clearSocketId, (state) => {
        state.socketId = undefined;
    });

    builder.addCase(setPresident, (state, action) => {
        state.isPresident = action.payload;
    });

    builder.addCase(setPageType, (state, action) => {
        state.pageType = action.payload;
    });

    builder.addCase(setName, (state, action) => {
        state.name = action.payload;
    });

    builder.addCase(setTeamType, (state, action) => {
        state.teamType = action.payload;
    });

    builder.addCase(setMemberToken, (state, action) => {
        state.memberToken = action.payload;
    });

    builder.addCase(clearMemberToken, (state) => {
        state.memberToken = undefined;
    });

    builder.addCase(setOpenRounds, (state, action) => {
        state.openRounds = action.payload;
    });

    builder.addCase(setRoundIndex, (state, action) => {
        state.roundIndex = action.payload;
    });

    builder.addCase(setDilemmaIndex, (state, action) => {
        state.dilemmaIndex = action.payload;
    });

    builder.addCase(setRollIndex, (state, action) => {
        state.rollIndex = action.payload;
    });

    builder.addCase(setVotingFinished, (state, action) => {
        state.isVotingFinished = action.payload;
    });

    builder.addCase(setScores, (state, action) => {
        state.scores = action.payload;
    });

    builder.addCase(setScore, (state, action) => {
        state.scores[action.payload[0]] = action.payload[1];
    });

    builder.addCase(setNotifications, (state, action) => {
        state.notifications = action.payload.map((n) => {
            return {
                ...n,
                addedAt: +new Date(),
                id: generateRandomId(),
            };
        });
    });

    builder.addCase(addNotification, (state, action) => {
        state.notifications = [
            ...state.notifications,
            {
                ...action.payload,
                addedAt: +new Date(),
                id: generateRandomId(),
            },
        ];
    });

    builder.addCase(setLoading, (state, action) => {
        state.isLoading = action.payload;
    });

    builder.addCase(setScreenSelection, (state, action) => {
        state.screenSelection = action.payload;
    });

    builder.addCase(addDilemmaVote, (state, action) => {
        const currentVoteIdx = state.dilemmaVotes.findIndex(dilemmaVote => (
            dilemmaVote.roundIndex === action.payload.roundIndex
            &&
            dilemmaVote.dilemmaIndex === action.payload.dilemmaIndex
        ));
        if (currentVoteIdx > -1) {
            // update array
            state.dilemmaVotes = [
                ...state.dilemmaVotes.slice(0, currentVoteIdx),
                action.payload,
                ...state.dilemmaVotes.slice(currentVoteIdx + 1)
            ]
        } else {
            // append
            state.dilemmaVotes = [...state.dilemmaVotes, action.payload];
        }
    });

});

export default rootReducer;
