import {PresidentDiceResult} from "../../../types/dice-result";
import {Dilemma} from "../../../types/dilemma";
import {PageType} from "../../../types/page-type";
import {RoundsData} from "../../../types/round";
import {Team, TeamAnswer, TeamResult, TeamTimer, TeamType, TeamVotes} from "../../../types/team";
import {VoteType} from "../../../types/vote-type";
import BackendLocal from "./backend-local";
import BackendSocket from "./backend-socket";
import {JoinTeamResponse, SelectDilemmaOptionResponse, VoteDilemmaResponse} from "../../../types/responses";
import {MEMBER_TOKEN_STORAGE_KEY} from "../../../shared/constants";
import {store} from "../../store";
import {
    addDilemmaVote,
    setDilemmaIndex,
    setMemberToken, setName, setPageType,
    setPresident,
    setRollIndex,
    setRoundIndex,
    setTeamType
} from "../../reducers/root-reducer";

export interface BackendBase {
    setPage(pageType: PageType): void;
    getName(): Promise<string | undefined>;
    getTeam(teamType: TeamType): Promise<Team>;
    joinTeam(teamType: TeamType): Promise<JoinTeamResponse>;
    reJoinTeam(tokenId: string): Promise<JoinTeamResponse|null>;
    getRoundsData(roundIndex: number): Promise<RoundsData>;
    switchRound(): Promise<number>;
    getTimer(roundIndex: number): Promise<TeamTimer>;
    getPresidentDiceRolls(): Promise<PresidentDiceResult[]>;
    getDilemma(roundIndex: number, dilemmaIndex: number): Promise<Dilemma>;
    getLastAnswer(roundIndex: number, dilemmaIndex: number): Promise<TeamAnswer | null>;
    selectDilemmaOption(roundIndex: number, dilemmaIndex: number, optionIndex: number): Promise<SelectDilemmaOptionResponse | null>;
    getVotes(): Promise<TeamVotes | null>;
    getVoteFinished(roundIndex: number, dilemmaIndex: number): Promise<boolean>;
    voteDilemma(roundIndex: number, dilemmaIndex: number, voteType: VoteType): Promise<VoteDilemmaResponse | null>;
    generateDilemmaResults(roundIndex: number, dilemmaIndex: number): Promise<TeamResult[]>;
    setRollIndex(rollIndex: number): Promise<void>;
    executePostDilemmaAction(roundIndex: number, dilemmaIndex: number): Promise<void>;
    processScore(roundIndex: number, dilemmaIndex: number, rollIndex: number): Promise<void>;
    onEnterBreakRoom(teamType: TeamType): void;
    onLeaveBreakRoom(teamType: TeamType): void;
}

export function joinTeamAndUpdateState(teamType: TeamType) {
    return backend.joinTeam(teamType).then((response) => {
        const team = response.team;
        // window.sessionStorage.removeItem(SCREEN_SELECTION_STORAGE_KEY);
        window.sessionStorage.setItem(MEMBER_TOKEN_STORAGE_KEY, JSON.stringify(response.token));
        store.dispatch(setMemberToken(response.token));
        store.dispatch(setTeamType(teamType));
        if (team.presidentId !== undefined && team.presidentId === response.token.id) {
            store.dispatch(setPresident(true));
            console.log("You are the president!");
        }

        // set the correct progress value
        store.dispatch(setRoundIndex(team.progress.roundIndex));
        store.dispatch(setDilemmaIndex(team.progress.dilemmaIndex));
        store.dispatch(setRollIndex(team.progress.rollIndex));
        store.dispatch(setPageType(team.progress.pageType));

        store.dispatch(setName(response.token.name));
    });
}

export function reJoinTeamAndUpdateState(tokenId: string) {
    return backend.reJoinTeam(tokenId).then((response) => {
        if (response === null) {
            throw new Error('null response on reJoinTeam');
        }
        const team = response.team;
        // update stored token in case there were changes
        window.sessionStorage.setItem(MEMBER_TOKEN_STORAGE_KEY, JSON.stringify(response.token));
        store.dispatch(setMemberToken(response.token));
        store.dispatch(setTeamType(team.type));
        if (team.presidentId === tokenId) {
            store.dispatch(setPresident(true));
            console.log("You are the president!");
        }

        // set the correct progress value
        store.dispatch(setRoundIndex(team.progress.roundIndex));
        store.dispatch(setDilemmaIndex(team.progress.dilemmaIndex));
        store.dispatch(setRollIndex(team.progress.rollIndex));
        store.dispatch(setPageType(team.progress.pageType));

        store.dispatch(setName(response.token.name));

        // restore votes that were already cast
        for (const answer of team.answers) {
            for (const memberVote of answer.voters) {
                if (memberVote.tokenId === tokenId) {
                    store.dispatch(addDilemmaVote({
                        roundIndex: answer.roundIndex,
                        dilemmaIndex: answer.dilemmaIndex,
                        vote: memberVote.vote,
                    }));
                }
            }
        }

        return team;
    });
}

class BackendService {
    private readonly _backend: BackendBase;

    constructor() {
        if (process.env.REACT_APP_BACKEND === "socket") {
            this._backend = new BackendSocket();
        } else {
            this._backend = new BackendLocal();
        }
    }

    public setPage(pageType: PageType): void {
        this._backend.setPage(pageType);
    }

    public getName(): Promise<string | undefined> {
        return this._backend.getName();
    }

    public getTeam(teamType: TeamType): Promise<Team> {
        return this._backend.getTeam(teamType);
    }

    public joinTeam(teamType: TeamType): Promise<JoinTeamResponse> {
        return this._backend.joinTeam(teamType);
    }

    public reJoinTeam(tokenId: string): Promise<JoinTeamResponse|null> {
        return this._backend.reJoinTeam(tokenId);
    }

    // public setReady(): Promise<ReadyResponse|null> {
    //     return this._backend.setReady();
    // }

    public getRoundsData(roundIndex: number): Promise<RoundsData> {
        return this._backend.getRoundsData(roundIndex);
    }

    public switchRound(): Promise<number> {
        return this._backend.switchRound();
    }

    public getTimer(roundIndex: number): Promise<TeamTimer> {
        return this._backend.getTimer(roundIndex);
    }

    public getPresidentDiceRoll(): Promise<PresidentDiceResult[]> {
        return this._backend.getPresidentDiceRolls();
    }

    public getDilemma(roundIndex: number, dilemmaIndex: number): Promise<Dilemma> {
        return this._backend.getDilemma(roundIndex, dilemmaIndex);
    }

    public getLastAnswer(roundIndex: number, dilemmaIndex: number): Promise<TeamAnswer | null> {
        return this._backend.getLastAnswer(roundIndex, dilemmaIndex);
    }

    public selectDilemmaOption(roundIndex: number, dilemmaIndex: number, optionIndex: number): Promise<SelectDilemmaOptionResponse | null> {
        return this._backend.selectDilemmaOption(roundIndex, dilemmaIndex, optionIndex);
    }

    public getVotes(): Promise<TeamVotes | null> {
        return this._backend.getVotes();
    }

    public getVoteFinished(roundIndex: number, dilemmaIndex: number): Promise<boolean> {
        return this._backend.getVoteFinished(roundIndex, dilemmaIndex);
    }

    public voteDilemma(roundIndex: number, dilemmaIndex: number, voteType: VoteType): Promise<VoteDilemmaResponse | null> {
        return this._backend?.voteDilemma(roundIndex, dilemmaIndex, voteType).then((response) => {
            if (response?.success === true) {
                // store this vote
                store.dispatch(addDilemmaVote({
                    roundIndex,
                    dilemmaIndex,
                    vote: voteType
                }));
            }

            // return original response for caller
            return response;
        });
    }

    public generateDilemmaResults(roundIndex: number, dilemmaIndex: number): Promise<TeamResult[]> {
        return this._backend.generateDilemmaResults(roundIndex, dilemmaIndex);
    }

    public setRollIndex(rollIndex: number): Promise<void> {
        return this._backend?.setRollIndex(rollIndex);
    }

    public executePostDilemmaAction(roundIndex: number, dilemmaIndex: number): Promise<void> {
        return this._backend?.executePostDilemmaAction(roundIndex, dilemmaIndex);
    }

    public processScore(roundIndex: number, dilemmaIndex: number, rollIndex: number): Promise<void> {
        return this._backend.processScore(roundIndex, dilemmaIndex, rollIndex);
    }

    public onEnterBreakRoom(teamType: TeamType): void {
        this._backend.onEnterBreakRoom(teamType);
    }

    public onLeaveBreakRoom(teamType: TeamType): void {
        this._backend.onLeaveBreakRoom(teamType);
    }
}

export const backend = new BackendService();